<template>
  <NavbarComponent />
  <SidebarComponentVue />
  <div dir="auto">
    <div class="body_content" :class="$i18n.locale == 'en' ? '' : 'body_content_ar'">
      <div class="row mb-5 mt-3 mx-1 py-5 px-3"
        style="border-radius: 10px;height: 400px;background-color: rgba(128, 128, 128, 0.33);">
        <div class="col-lg-4">
          <doughnut-component></doughnut-component>
        </div>
        <div class="col-lg-8">
          <Line id="my-chart-id1" :data="data" :options="options" />
        </div>
      </div>
      <div class="row mb-5 mt-3 mx-1  py-5 px-3"
        style="border-radius: 10px;height: 400px;background-color: rgba(128, 128, 128, 0.33);">
        <div class="col-lg-6">
          <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
        </div>
        <div class="col-lg-6">
          <DoughnutComponentChart />
        </div>
      </div>
      <!-- <HomeComponent /> -->
    </div>
  </div>
</template>
<script>
import { Bar, Line } from 'vue-chartjs'
// import HomeComponent from '@/components/business/dashboard/HomeComponent.vue';
import SidebarComponentVue from '@/components/business/layout/SidebarComponent.vue';
import NavbarComponent from '@/components/business/layout/NavbarComponent.vue';
import {
  Chart as ChartJS, Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale, ArcElement, LinearScale
} from 'chart.js'
ChartJS.register(Title, Tooltip,
  PointElement,
  LineElement,
  Legend, BarElement, CategoryScale, ArcElement, LinearScale)
import DoughnutComponent from '@/components/business/dashboard/DoughnutComponent.vue'
import DoughnutComponentChart from '@/components/business/dashboard/DoughnutComponentChart.vue'
export default {
  name: "HomeView",
  components: {
    SidebarComponentVue,
    NavbarComponent,
    // HomeComponent,
    DoughnutComponent,
    DoughnutComponentChart,
    Bar,
    Line,
  },
  data() {
    return {
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: this.$t('Number of reservations'),
            backgroundColor: '#fffff',
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false
      },

      chartData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        // datasets: [ { data: [40, 20, 12] } ]
        datasets: [
          {
            label: this.$t("Number of reservations"),
            backgroundColor: '#D1Af77',
            data: [100, 20, 12, 100, 20, 12, 100, 20, 12, 100, 20, 12]
          }
        ]
      },
      chartOptions: {
        responsive: true
      },


    }
  }
};
</script>